.cell {
  position: relative;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #777777;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 60px -10px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.5s;
  font-size: 18px;
  /* line-height: 10px; */
}

.default {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.cell:hover {
  box-shadow: 0px 20px 60px -10px rgba(0, 0, 0, 0.3);
}

.shuffle {
  position: absolute;
  top: 40px;
  left: 40px;
  padding: 0px 20px 0px 20px;
  width: auto;
  height: auto;
  background: white;
  border: solid 2px #ffaad4;
  border-radius: 7px;
  color: #ffaad4;
  /* z-index: 100000; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 100;
  line-height: 45px;
}

.shuffle:hover {
  background: #f9f9f9;
}

.details {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: calc(100% - 2.5rem);
  height: calc(100% - 2.5rem);
  background: #ffffffa0;
  color: white;
  padding: 20px;
  font-weight: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.skills {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: calc(100% - 2.5rem);
  height: calc(100% - 2.5rem);
  background: #ffffffa0;
  color: white;
  padding: 20px;
  font-weight: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.myself {
  position: absolute;
  bottom: 0px;
  width: calc(100% - 2.5rem);
  background: #ffffffa0;
  color: white;
  padding: 20px;
  font-weight: 100;
  z-index: 2;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: space-between; */
  align-items: center;
}


.myself h1 {
  color: #ca6a9a;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  /* max-width: 50px; */
}

.myself p {
  color: #777777;
  font-size: 14px;
  line-height: 14px;
  margin: 0;
  padding-top: 6px;
}

.details h1 {
  color: #ca6a9a;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  max-width: 100px;
}

.details p {
  color: #777777;
  font-size: 15px;
  line-height: 18px;
  margin: 0;
  padding-top: 6px;
}
.skills h1 {
  color: #ca6a9a;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  max-width: 110px;
}

.skills p {
  color: #777777;
  font-size: 14px;
  line-height: 14px;
  margin: 0;
  padding-top: 6px;
}

.circle {
  /* position: absolute;
  top: 40%;
  left: 40%; */
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0px 20px 60px -10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-self: center;
  margin-bottom: 2rem;
}
/* .simple-trans-main {
  height: 16rem;
  width: 100%;
  margin-bottom: 2rem;
} */

.simple-trans-main > div {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 800;
  font-size: 5em;
  will-change: transform, opacity;
  text-shadow: 0px 2px 40px #00000020, 0px 2px 5px #00000030;
}
.skillc {
  position: relative;
  top: 10%;
  left: 10%;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  box-shadow: 0px 20px 60px -10px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
}

.close {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 20px;
  color: #777777;
}

.main {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 30px 70px 30px 70px;
  height: auto;
  display: flex;
}

.grid {
  flex: 1;
}

.ant-btn-primary {
  background-color: #ca6a9a !important;
  border-color: #ca6a9a !important;
}
